import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import {
  MdOutlineAnalytics,
  MdOutlineContactPhone,
  MdOutlineContactSupport,
  MdOutlineDns,
} from 'react-icons/md';

const routesConfig = [
  {
    id: 'app',
    title: 'Aplicação',
    messageId: 'Aplicação',
    type: 'group',
    children: [
      {
        id: 'crypto',
        title: 'Crypto',
        messageId: 'Encaminhamento lista',
        type: 'item',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
        icon: <MdOutlineContactPhone />,
        url: '/encaminamento-lista',
      },
    ],
  },
  {
    id: 'alterar_minha_senha',
    title: 'Alterar minha senha',
    messageId: 'Alterar minha senha',
    type: 'item',
    permittedRole: [RoutePermittedRole.Admin],
    icon: <MdOutlineContactSupport />,
    url: '/alterar-minha-senha',
  },
  {
    id: 'usuario',
    title: 'Usuários',
    messageId: 'Usuários',
    type: 'item',
    permittedRole: [RoutePermittedRole.Admin],
    icon: <MdOutlineAnalytics />,
    url: '/usuario',
  },
  {
    id: 'empresa',
    title: 'Empresas',
    messageId: 'Empresas',
    type: 'item',
    permittedRole: [RoutePermittedRole.Admin],
    icon: <MdOutlineDns />,
    url: '/empresa',
  },
];
export default routesConfig;
