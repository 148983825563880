import {RoutePermittedRole} from '@crema/constants/AppEnums';
import React from 'react';

const EncaminamentoLista = React.lazy(
  () => import('../../../modules/dashboards/lista/EncaminamentoListas'),
);

export const dashBoardConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/encaminamento-lista',
    element: <EncaminamentoLista />,
  },
];
