import React from 'react';
import {Navigate} from 'react-router-dom';
import {authRouteConfig} from './AuthRoutes';
import Error403 from '../../../modules/errorPages/Error403';
import {errorPagesConfigs} from './ErrorPagesRoutes';
import {dashBoardConfigs} from './DashboardsRoutes';
import {accountPagesConfigs} from './AccountRoutes';
import {EmpresaPagesConfigs} from './EmpresaRoutes';

const authorizedStructure = (loginUrl: any) => {
  return {
    fallbackPath: loginUrl,
    unAuthorizedComponent: <Error403 />,
    routes: [
      ...dashBoardConfigs,
      ...accountPagesConfigs,
      ...EmpresaPagesConfigs,
    ],
  };
};
const publicStructure = (initialUrl: any) => {
  return {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
  };
};
const anonymousStructure = (initialUrl: any) => {
  return {
    routes: errorPagesConfigs.concat([
      {
        path: '/',
        element: <Navigate to={initialUrl} />,
      },
      {
        path: '*',
        element: <Navigate to='/error-pages/error-404' />,
      },
    ]),
  };
};

export {authorizedStructure, publicStructure, anonymousStructure};
