import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const ChangePassword = React.lazy(
  () => import('../../../modules/alterar-senha'),
);

const UsuarioTable = React.lazy(
  () => import('../../../modules/usuario/UsuarioTable'),
);

const UsuarioFormEditar = React.lazy(
  () => import('../../../modules/usuario/UsuarioFormEditar'),
);

const UsuarioFormNovo = React.lazy(
  () => import('../../../modules/usuario/UsuarioFormNovo'),
);

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/alterar-minha-senha',
    element: <ChangePassword />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/usuario',
    element: <UsuarioTable />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/usuario/:id/editar/',
    element: <UsuarioFormEditar />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/usuario/novo/',
    element: <UsuarioFormNovo />,
  },
];
