import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const EmpresaTable = React.lazy(
  () => import('../../../modules/empresa/EmpresaTable'),
);

const EmpresaFormEditar = React.lazy(
  () => import('../../../modules/empresa/EmpresaFormEditar'),
);

const EmpresaFormNovo = React.lazy(
  () => import('../../../modules/empresa/EmpresaFormNovo'),
);

export const EmpresaPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/empresa',
    element: <EmpresaTable />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/empresa/:id/editar/',
    element: <EmpresaFormEditar />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/empresa/novo/',
    element: <EmpresaFormNovo />,
  },
];
