import React from 'react';
import {Box} from '@mui/material';
type AppLogoProps = {
  color?: string;
};
const AppLogo: React.FC<AppLogoProps> = ({color}) => {
  return (
    <Box
      sx={{
        height: {xs: 56, sm: 70},
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: {xs: 40, sm: 45},
        },
      }}
      className='app-logo'
    >
      <img src='favicon.png' style={{height: '100px'}} />
    </Box>
  );
};
export default AppLogo;
