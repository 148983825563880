import axios from '@crema/services/axios';
import type {AxiosResponse} from 'axios';

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createAxiosClient = () => {
  const client = jwtAxios.interceptors.response.use(
    (res: any) => {
      return jwtAxios.defaults.headers.common.Authorization;
    }, //: AxiosResponse<any, any>
    (err: any) => {
      if (err.response && err.response.data.msg === 'Token is not valid') {
        console.log('Need to logout user');
        // store.dispatch({type: LOGOUT});
      }
      return Promise.reject(err);
    },
  );
  return client;
};

export const setAuthToken = (token?: string, data?: any) => {
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('token', token);
    if (data !== null && data !== undefined) {
      localStorage.setItem('usuario', data.id);
      localStorage.setItem('nomeEmpresa', data.nomeEmpresa);
      localStorage.setItem('cnpjEmpresa', data.cnpjEmpresa);
      localStorage.setItem('urlImagem', data.urlImagem);
      localStorage.setItem('idEmpresa', data.idEmpresa);
      localStorage.setItem('tipoUsuario', data.tipoUsuario);
      localStorage.setItem('email', data.email);
      localStorage.setItem('nomeUsuario', data.nomeUsuario);
    }
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('nomeEmpresa');
    localStorage.removeItem('cnpjEmpresa');
    localStorage.removeItem('urlImagem');
    localStorage.removeItem('idEmpresa');
    localStorage.removeItem('tipoUsuario');
    localStorage.removeItem('email');
    localStorage.removeItem('nomeUsuario');
  }
};

export default jwtAxios;
